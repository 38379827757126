import React from 'react';
import { Container } from 'react-bootstrap';

import NavbarComponent from '../../components/navbar';
import Footer from '../../components/footer';
import { Helmet } from 'react-helmet';
// import { useCookies } from 'react-cookie';

import Logo from '../../assets/produtos/cea.png';
import ColunaTop from '../../components/layout/page_cupom_top/';
import ColunaEsquerda from '../../components/layout/page_cupom_esquerda/';
import ColunaDireita from '../../components/layout/page_cupom_direita/';


export default function CupomCea() {

    //sistema biscoito
    // var url_ = new URL(window.location.href);
    // var safety_ad = url_.searchParams.get("watch");
    // const [cookies, setCookie] = useCookies(['name']);

    // function definiCookie() {
    //     setCookie('real', 'yes', { path: '/', maxAge: 7200 });
    //     setCookie('loja', 'd', { path: '/', maxAge: 7200 });

    //     window.location.href = '/cupom-cealoja';
    // }
    // function definiCookie2() {
    //     setCookie('real', 'yes', { path: '/', maxAge: 7200 });
    //     setCookie('loja', 'p', { path: '/', maxAge: 7200 });

    //     window.location.href = '/cupom-cealoja';
    // }
    // if (safety_ad === "t") {
    //     definiCookie();
    // }
    // if (safety_ad === "p") {
    //     definiCookie2();
    // }
    // // verificações

    // var codigo = "gate";

    // var urlLoja = "https://bit.ly/3nXwrRw";

    // if (cookies.loja === "d") {
    //     codigo = "gate";
    // } else if (cookies.loja === "p") {
    //     codigo = "fort";

    //     urlLoja = "https://bit.ly/3KUvbYe";
    // }

    var codigo = "gate";

    var urlLoja = "https://bit.ly/3nXwrRw";

    var dados_cupom = {
        loja: {
            nome: "C&A",
            logo: Logo,
            facebook: 'https://pt-br.facebook.com/ceaBrasil',
            instagram: 'https://www.instagram.com/cea_brasil/',
            razao: 'C&A Modas Ltda',
            cnpj: '45.242.914/0001-05',
            descricao: 'É uma varejista multinacional focada em moda, calçados e acessórios no melhor estilo fast fashion. Encontre o melhor da moda feminina, masculina e infantil, incluindo as últimas tendências e coleções com personalidades, por preços justos e muita qualidade.',
        },
        cupom: [
            {
                tipo_desc: "10%",
                name_desc: "Código Consultor - 10% OFF",
                descri_desc: "Válido para compras acima de R$ 99,90.",
                buttonText: "Pegar Cupom",
                openLoja: urlLoja,
                codigo: "gate",
                tipoButton: "Cupom",
                especial: "cea",
            },
            {
                tipo_desc: "8%",
                name_desc: "Novidades C&A - 8% de Desconto",
                descri_desc: "Seleção de novidades com 8% de desconto em compras acima de R$: 99,90.",
                buttonText: "Ativar Desconto",
                openLoja: urlLoja,
                codigo: codigo,
                tipoButton: "Cupom",
            }
        ]
    }

    return (
        <>
            {(
                <>
                    <header>
                            <Helmet>
                                <title>Cupom de Desconto {dados_cupom.loja.nome}</title>
                                <meta
                                    name="description" content="Ganhe desconto na C&A utilizando códigos de descontos!" />

                                <script>{`function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') {window.location = url; } }; gtag('event', 'conversion', {'send_to': 'AW-994398535/sAQKCOfN7PsCEMeildoD', 'event_callback': callback }); return false; }`}</script>

                            </Helmet>
                            <NavbarComponent />
                        </header>
                        <main>
                            <article>
                                <Container>
                                <div className="lojaDestaque d-flex justify-content-center">
                                    <ColunaTop api={dados_cupom.loja} />
                                </div>


                                    <div className="d-flex justify-content-center">
                                        <ColunaEsquerda api={dados_cupom} />
                                        {/* <ColunaDireita api={dados_cupom} /> */}
                                    </div>
                                </Container>

                            </article>
                        </main>
                        <footer className="footer">
                            <Container className="containerCustom ">
                                <Footer />
                            </Container>
                        </footer>
                </>
            )}

        </>
    )
}