import 'bootstrap/dist/css/bootstrap.min.css';
import './style/global.css'

import Caminhos from './routes';

function App() {
  return (
    <div className="App">
      <Caminhos />
    </div>
  );
}

export default App;
