import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Time_Icon from '../../../assets/icons/timeout.svg';
import Tuto from '../../../assets/gate.png';

// const random = Math.floor(Math.random() * callAd.length);


function changeTextCopy() {
    document.getElementById("copyCupom").innerHTML = "Copiado!";
}

//sistema click automatico
var url_ = new URL(window.location.href);
var numberProduto = url_.searchParams.get("p");

function checkUrl(key) {
    if (key === 0) {
        // disparaAdword();
    }
    var newUrl = url_.host + url_.pathname + "?p=" + key;
    window.open("http://" + newUrl, "_blank");
}

export default function Cupom_Esquerda(props) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function disparaModal() {
        handleShow();
    }

    useEffect(() => {
        if (!numberProduto) {
        } else {
            window.onload = function () {
                document.getElementById("open").click();
            }
        }
    });

    return (
        <>
            <div className="col-md-8">
                {props.api.cupom.map((i, key) => {
                    return <div key={key} className="borderCaixaCupomNew bgWhite p-4 mb-3">
                        {!numberProduto ? <></> : <div id="open" onClick={disparaModal}></div>}

                        <div className="d-flex align-items-center justify-content-start">
                            <div>
                                <div className="d-flex flex-column boxPorcentagem text-center d-flex align-items-center justify-content-center"><div className="d-flex align-items-center textPorcentagem ps-3 pe-3">{i.tipo_desc}
                                </div>
                                    <div className="pt-1 subTextPorcentagem">{i.tipoButton}</div>
                                </div>
                            </div>
                            <div className="boxCupom2 w-100 ms-4 me-4">
                                <div><p>{i.name_desc}</p>
                                    {i.especial === "riachuelo" ? <span>Use o cupom: <b>{i.codigo}</b> e ganhe 12% de desconto nas compras acima de R$ 99,90.</span> : <></>}
                                    {i.especial === "cea" ? <span>Digite: <b>{i.codigo}</b> no campo "<u>código do consultor</u>" dentro do carrinho de compras e garanta 10% de desconto nas compras acima de R$: 99,90.</span> : <></>}
                                    {i.especial === "ceabonus" ? <span>Nunca comprou na loja física ou site do C&A? Garanta 20% off em seu primeiro pedido usando a combinação de código de consultor <b>{props.api.cupom[0].codigo}</b> com o cupom <b>NOVOMINHA</b>.</span> : <></>}
                                    {i.especial !== "cea" & i.especial !== "ceabonus" & i.especial !== "riachuelo" ? <span>{i.descri_desc}</span> : <></>}

                                </div>
                                <br />
                                <div className='textValida'>
                                    <div className='d-flex justify-content-center'><img alt="Status Verificado" src={Time_Icon} width="15px" height="15px" className='me-1' /></div>
                                    <div><span>{!i.validade ? <>Verificado</> : <>Expira em {i.validade}</>}</span></div>
                                </div>

                            </div>

                            <div className="w-50 hideButtonDesktop">
                                {i.tipoButton === "Cupom" ?
                                    <>

                                        <CopyToClipboard text={i.codigo}>
                                            <a rel="nofollow" href={i.openLoja} onClick={() => { checkUrl(key) }}>
                                                <div className="boxCupom" onClick={disparaModal}>
                                                    <div className="borderCupom w-100"></div>
                                                    <div className="openCupom d-flex align-items-center justify-content-center"><span>Pegar Cupom</span></div>
                                                </div>
                                            </a>
                                        </CopyToClipboard>
                                    </>
                                    :
                                    <div className="boxCupom mt-3">
                                        <a href={i.openLoja} target="_blank" rel="noreferrer" >
                                            <div className="borderCupom w-100"></div>
                                            <div className="openCupom d-flex align-items-center justify-content-center"><span>Ativar Desconto</span></div>
                                        </a>
                                    </div>
                                }

                            </div>

                        </div>
                        <div className="hideButtonMobile">
                            {i.tipoButton === "Cupom" ?
                                <CopyToClipboard text={i.codigo}>
                                    <a rel="nofollow" href={i.openLoja} onClick={() => { checkUrl(key) }}>
                                        <div className="boxCupom mt-3" onClick={disparaModal}>
                                            <div className="borderCupom w-100"></div>
                                            <div className="openCupom d-flex align-items-center justify-content-center"><span>Pegar Cupom</span></div>
                                        </div>
                                    </a>
                                </CopyToClipboard>
                                :
                                <div className="boxCupom mt-3">
                                    <a href={i.openLoja} target="_blank" rel="noreferrer" >
                                        <div className="borderCupom w-100"></div>
                                        <div className="openCupom d-flex align-items-center justify-content-center"><span>Ativar Desconto</span></div>
                                    </a>
                                </div>
                            }
                        </div>


                    </div>
                })}

                <div className="">
                    <div id="anchor1">
                        <h1 className='boxColunaDireita'>Como usar cupom de desconto</h1>
                        <ol>
                            <li>Escolha suas peças e adicione no carrinho</li>
                            <li>Ao finalizar digite: <b>gate</b> na parte de <b>código da consultora</b> para aproveitar o desconto!</li>
                            
                            <img src={Tuto} width="100%" className="pt-3 pb-3" alt="Loja"/>
                        </ol>
                    </div>
                    <div id="anchor2">
                        <h1 className='boxColunaDireita'>{props.api.loja.nome} é confiável?</h1>
                        <p>Para saber mais informações sobre a {props.api.loja.nome}, você pode visitar a página da loja nos sites Reclame Aqui e Ebit. Neles estão as informações de reputação da loja, índice de satisfação de seus usuários e índice de solução das solicitações.</p>
                    </div>
                    <div id="anchor3">
                        <h1 className='boxColunaDireita'>Redes sociais da {props.api.loja.nome}</h1>
                        <p>Facebook: <a className="linksColunaDireta" href={props.api.loja.facebook} target="_blank" rel="noreferrer">{props.api.loja.facebook}</a></p>
                        <p>Instagram: <a className="linksColunaDireta" href={props.api.loja.instagram} target="_blank" rel="noreferrer">{props.api.loja.instagram}</a></p>
                        <p>Razão Social: {props.api.loja.razao}</p>
                        <p>CNPJ: {props.api.loja.cnpj}</p>
                    </div>
                    <br/>
                </div>
            </div>
            {numberProduto === "" ? <></>
                :
                <Modal centered show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{!numberProduto ? <></> : props.api.cupom[numberProduto].name_desc}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Helmet>
                            {/* <script type="text/javascript">
                                gtag_report_conversion()
                            </script> */}
                        </Helmet>
                        <div class="alert alert-warning" role="alert">
                            <span>
                                {!numberProduto ? <></> :
                                    <>
                                        {props.api.cupom[numberProduto].especial !== "cea" & props.api.cupom[numberProduto].especial !== "ceabonus" ? <>Copie e cole o cupom abaixo no carrinho de compras.</> : <></>}
                                    </>}
                                {!numberProduto ? <></> :
                                    <>
                                        {props.api.cupom[numberProduto].especial === "cea" ?
                                            <>Copie o código e cole no campo <b>código do consultor</b> no carrinho de compras ou na tela de pagamento da loja C&A.</>
                                            : <></>}
                                        {props.api.cupom[numberProduto].especial === "ceabonus" ?
                                            <>Combine o <u>código de consultor</u> <b>{props.api.cupom[0].codigo}</b> e <u>cupom</u> <b>NOVOMINHA</b> para obter o desconto.</>
                                            : <></>}
                                    </>}
                            </span>
                        </div>
                        {props.api.loja.nome !== "Riachuelo" && props.api.loja.nome !== "C&A" ?
                            <>
                                <CopyToClipboard text={!numberProduto ? <></> : props.api.cupom[numberProduto].codigo}>
                                    <div className="d-flex mb-3 p-1 borderCupom2">
                                        <input type="text" readOnly value={!numberProduto ? <></> : props.api.cupom[numberProduto].codigo} className="inputCupom w-100 inputClick" />
                                        <div className="input-group-append">
                                            <a href={!numberProduto ? <></> : props.api.cupom[numberProduto].openLoja} target="_blank" rel="noreferrer">
                                                <button id="copyCupom" className="d-flex align-items-center justify-content-center buttonCupomOpen rounded-3 ps-4 pe-4" type="button" onClick={changeTextCopy}>Copiar e ir para a loja</button>
                                            </a>
                                        </div>
                                    </div>
                                </CopyToClipboard>
                            </> :
                            <>
                                <CopyToClipboard text={!numberProduto ? <></> : props.api.cupom[numberProduto].codigo}>
                                    <div className="d-flex mb-3 p-1 borderCupom2">
                                        <input type="text" readOnly value={!numberProduto ? <></> : props.api.cupom[numberProduto].codigo} className="inputCupom w-100 inputClick" />
                                        <div className="input-group-append">
                                            <button id="copyCupom" className="d-flex align-items-center justify-content-center buttonCupomOpen rounded-3 ps-4 pe-4" type="button" onClick={changeTextCopy}>Copiar</button>
                                        </div>
                                    </div>
                                </CopyToClipboard>

                                {/* <CopyToClipboard text={!numberProduto ? <></> : props.api.cupom[numberProduto].codigo}>
                                    <div className="d-flex mb-0 p-1 borderCupom rounded-3">
                                        <input type="text" readOnly value={!numberProduto ? <></> : props.api.cupom[numberProduto].codigo} className="inputCupom w-100 inputClick" />
                                        <div className="input-group-append">
                                            <button id="copyCupom" className="d-flex align-items-center justify-content-center buttonCupomClick rounded-3 ps-4 pe-4" type="button" onClick={changeTextCopy}>Copiar</button>
                                        </div>
                                    </div>
                                </CopyToClipboard> */}
                                
                                {/* <CopyToClipboard text={!numberProduto ? <></> : props.api.cupom[numberProduto].codigo} className="mt-3 mb-4">
                                    <center><a href={!numberProduto ? <></> : props.api.cupom[numberProduto].openLoja} target="_blank" rel="noreferrer"><button className="w-100 ps-4 pe-4 mt-0 buttonCupomLoja rounded-3" type="button" onClick={changeTextCopy}>Copiar e ir para a loja</button></a></center>
                                </CopyToClipboard> */}
                            </>}

                        <p className="textColunaDireita">Regras do cupom</p>

                        <span>{!numberProduto ? <></> : props.api.cupom[numberProduto].descri_desc}</span>

                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            }

        </>
    )
}