import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';

var dadosLojas = [
    {
        nameLoja: 'Netshoes',
        caminhoLoja: '../cupom-netshoes'
    },
    {
        nameLoja: 'Riachuelo',
        caminhoLoja: '../cupom-riachuelo'
    },
    {
        nameLoja: 'Mercatto',
        caminhoLoja: '../cupom-mercatto'
    },
    {
        nameLoja: 'Shopee',
        caminhoLoja: '../cupom-Shopee'
    },
    {
        nameLoja: 'Aliexpress',
        caminhoLoja: '../cupom-aliexpress'
    },
    {
        nameLoja: 'Jequiti',
        caminhoLoja: '../cupom-jequiti'
    },
    {
        nameLoja: 'Booking',
        caminhoLoja: '../cupom-booking'
    },
    {
        nameLoja: 'Vaio',
        caminhoLoja: '../cupom-vaio'
    },
    {
        nameLoja: 'Buser',
        caminhoLoja: '../cupom-buser'
    },
    {
        nameLoja: 'Madesa',
        caminhoLoja: '../cupom-madesa'
    },
    {
        nameLoja: 'Rentcars',
        caminhoLoja: '../cupom-rentcars'
    },
    {
        nameLoja: 'Usaflex',
        caminhoLoja: '../cupom-usaflex'
    }
]

export default function SearchForm() {

    const [searchTerm, setSearchTerm] = useState('');

    return <>
        <div className="d-flex flex-column searchTam">
            <div class="d-flex flex-row rounded">
                <FormControl className="formSearchFix border border-0"
                    placeholder="Busque pelo nome da loja..."
                    aria-describedby="basic-addon2"
                    onChange={event => { setSearchTerm(event.target.value) }}
                />
                <div className="formSearchFix2 p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" aria-hidden="true" class="crayons-icon c-btn__icon" focusable="false"><path d="M15.5 14h-.8l-.28-.27A6.47 6.47 0 0 0 16 9.5a6.5 6.5 0 1 0-13 0A6.5 6.5 0 0 0 9.5 16a6.47 6.47 0 0 0 4.23-1.57l.27.28v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z" /></svg>
                </div>
            </div>
            {dadosLojas.filter((i, key) => {
                if (searchTerm === "") {
                    return ""
                } else if (i.nameLoja.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return i;
                }
                return ""
            }).map((i, key) => {
                return <a href={i.caminhoLoja}><div className="boxSearch searchTam2">
                <li key={key} className='border-top'>{i.nameLoja} {console.log("nome: " + i.nameLoja)}</li>
            </div></a>
            })}

        </div>


    </>
}