import React from 'react';
import { Container } from 'react-bootstrap';

export default function Cupom_Top(props) {
    return (
        <>
                <div className='col-md-8'>
                <div className="pt-3 pb-3">
                    <div className='d-flex lojaHeader pb-3'>
                        <div className='borderCaixaCupomNew lojaHeader-logo'><img src={props.api.logo} alt="Loja"/></div>
                        <div className='d-flex flex-column justify-content-center'>
                            <div>
                                <h1>Cupom de Desconto {props.api.nome}</h1>
                            </div>
                            {/* <div>
                        <p>Abaixo você encontra TOP códigos promocionais e promoções para a loja {props.api.nome}.</p>
                    </div> */}
                            {/* <div>
                                <div>Filtrar por:</div>
                                <div className="d-flex">
                                    <div className="border border-1">Códigos</div>
                                    <div className="border border-1">Links</div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="subTitle">
                        Abaixo você encontra os melhores cupons, códigos promocionais e promoções para a loja {props.api.nome}.
                    </div>
                    </div>
                </div>
        </>
    )
}