import React, { useState } from 'react';
import { Navbar, Nav, Container, Button, Modal } from 'react-bootstrap';

import logoKing from '../../assets/logo.png';

import SearchForm from '../search';

export default function NavbarComponent() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function disparaModal() {
        handleShow();
    }

    return (
        <>
            <div className="menu-bar menu-text-color ">
                {/* <Navbar collapseOnSelect expand="lg" className="navbar-dark"> */}
                <Navbar collapseOnSelect className="navbar-dark">
                    <Container className="containerCustom marginMobile">

                        <div>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav bg-dark" />
                            <Navbar.Brand href="#"><img width="120px" alt="Logotipo" src={logoKing} /></Navbar.Brand>

                        </div>

                        {/* <div className="searchExibeDesktop" onClick={disparaModal}>
                            <SearchForm />
                        </div>
                        <div className="searchMobileExibeIcon">
                            <Button className="border border-0 rounded" alt="Pesquisar" title="Pesquisar" variant="" onClick={disparaModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" aria-hidden="true" class="crayons-icon c-btn__icon" focusable="false"><path d="M15.5 14h-.8l-.28-.27A6.47 6.47 0 0 0 16 9.5a6.5 6.5 0 1 0-13 0A6.5 6.5 0 0 0 9.5 16a6.47 6.47 0 0 0 4.23-1.57l.27.28v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z" fill="rgb(255,255,255)" /></svg>
                            </Button>
                        </div>

                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                            </Nav>
                            <Nav>
                                <Nav.Link href="../contato" className="me-1">Contato</Nav.Link>
                            </Nav>
                        </Navbar.Collapse> */}


                    </Container>
                </Navbar>

                <Modal centered show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>O que deseja?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SearchForm />
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}