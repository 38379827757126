import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/home';
import Contato from '../pages/contato';
import CupomCea from '../pages/cupom-cea';
import CupomMercatto from '../pages/cupom-mercatto';
import CupomRiachuelo from '../pages/cupom-riachuelo';
import CupomKwai from '../pages/cupom-kwai';
import CupomShopee from '../pages/cupom-shopee';
import CupomMadesa from '../pages/cupom-madesa';
import CupomBuser from '../pages/cupom-buser';
import CupomTon from '../pages/cupom-ton';
import CupomNetshoes from '../pages/cupom-netshoes';
import CupomBooking from '../pages/cupom-booking';
import Jequiti from '../pages/cupom-jequiti';
import Vaio from '../pages/cupom-vaio';
import Usaflex from '../pages/cupom-usaflex';
import Rentcars from '../pages/cupom-rentcars';
import Aliexpress from '../pages/cupom-aliexpress';
import RecargaPay from '../pages/cupom-recargapay';


export default function Caminhos() {
    return (
        <>
            <Routes>
                {/* <Route path="/" element={<Home />} title="Home" />

                <Route path="/contato" element={<Contato />} title="Contato" /> */}
                <Route path="/cupom-cea" element={<CupomCea />} title="Cupom C&A" /> 

                {/* <Route path="/cupom-mercatto" element={<CupomMercatto />} title="Cupom Mercatto" />
                <Route path="/cupom-riachuelo" element={<CupomRiachuelo />} title="Cupom Riachuelo" />
                <Route path="/cupom-kwai" element={<CupomKwai />} title="Cupom Kwai" />
                <Route path="/cupom-shopee" element={<CupomShopee />} title="Cupom Shopee" />
                <Route path="/cupom-madesa" element={<CupomMadesa />} title="Cupom Madesa" />
                <Route path="/cupom-buser" element={<CupomBuser />} title="Cupom Buser" />
                <Route path="/cupom-ton" element={<CupomTon />} title="Cupom Ton" />
                <Route path="/cupom-netshoes" element={<CupomNetshoes />} title="Cupom Netshoes" />
                <Route path="/cupom-booking" element={<CupomBooking />} title="Cupom Boking" />
                <Route path="/cupom-jequiti" element={<Jequiti />} title="Cupom Jequiti" />
                <Route path="/cupom-vaio" element={<Vaio />} title="Cupom Vaio" />
                <Route path="/cupom-usaflex" element={<Usaflex />} title="Cupom Usaflex" />
                <Route path="/cupom-rentcars" element={<Rentcars />} title="Cupom Rentcars" />
                <Route path="/cupom-aliexpress" element={<Aliexpress />} title="Cupom Aliexpress" />
                <Route path="/cupom-recargapay" element={<RecargaPay />} title="Cupom RecargaPay" /> */}

            </Routes>
        </>
    )
}